/* 
export const sections = {
    "Consumer Insights": { "value": "consumer-insights" },
    "Great Ideas": { "value": "great-ideas" },
    "Business Strategy": { "value": "business-strategy" },
    "Creative Direction": { "value": "creative-direction" },
    "Travel Guides": { "value": "travel-guides" },
    "Merchandising": { "value": "merchandising" },
    "Testing": { "value": "creative-direction" },
} */



export const sections = {
    "Consumer Insights": { "value": "consumer-insights" },
    "Great Ideas": { "value": "great-ideas" },
    "Business Strategy": { "value": "business-strategy" },
    "Creative Direction": { "value": "creative-direction" },
    "Travel Guides": { "value": "travel-guides" },
    "Merchandising": { "value": "merchandising" },
    "Testing": { "value": "creative-direction" },
    "Design Movements": { "value": "design-movements" },
    "Business Insights": { "value": "business-insights" },
    "Color and Print": { "value": "color-and-print" },
    "Runway and Research": { "value": "runway-and-research" },
    "The Weekly": { "value": "the-weekly" },
    "In The Press": { "value": "in-the-press" },
}


export const templateTypes = {
    "consumerInsight": { "value": "consumer-insights" },
    "greatIdea": { "value": "great-ideas" },
    "bigThinking": { "value": "business-strategy" },
    "travelGuide": { "value": "travel-guides" },
    "customWork": { "value": "custom-work" },
    "merchandising": { "value": "merchandising" }
}

const prod = {
    //API_URL: `https://api.donegertobe.com`,
    //API_URL: `https://development.doneger.com`
    API_URL: `https://api.damonsit.com`,
    //API_URL: `http://localhost:30000`,
    //API_URL: `https://api.doneger.com`,
    IMG_SRV: `https://assets.tobereport.com`
}
const dev = {
    API_URL: `http://localhost:30000`,
    //API_URL: `https://api.damonsit.com`,
    //API_URL: 'https://development.doneger.com'
    //API_URL: `https://api.donegertobe.com`,
    //API_URL: `https://api.doneger.com`,
    IMG_SRV: `https://assets.tobereport.com`,
};

export const config = process.env.NODE_ENV === `development` ? dev : prod;